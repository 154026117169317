import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/common/components/navbar.module.scss'

const Item = ({ children, active, ...attrs }) => {
  const { className } = attrs

  attrs.className = classNames(className, {
    [styles.item]: true,
    [styles.active]: active
  })
  let TagName = 'div'
  const { href } = attrs
  if (href) {
    TagName = 'a'
  }

  return (
    <TagName {...attrs}>
      {children}
    </TagName>
  )
}

Item.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  active: PropTypes.bool
}

export default Item
