import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/common/components/navbar.module.scss'
import Item from './item'

const Divider = ({ ...attrs }) => {
  const { className } = attrs

  attrs.className = classNames(className, {
    [styles.divider]: true
  })

  return (
    <Item {...attrs} />
  )
}
Divider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default Divider
