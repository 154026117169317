import PropTypes from 'prop-types'

import BaseNavbar from '../../common/navbar'
import Icon from '../icon'

import CloseIcon from '../../common/icon/close.svg'
import MenuIcon from '../../common/icon/menu.svg'

const MobileMenuTrigger = ({ isMenuOpen, toggleMenu }) => {
  return <BaseNavbar.Item
    className={'cursor-pointer'}
    onClick={toggleMenu}
    style={{
      padding: 0
    }}>
    <Icon
      width={24}
      height={24}
      svg={
        isMenuOpen
          ? CloseIcon
          : MenuIcon
      }
      className={'text-white'} />
  </BaseNavbar.Item>
}

MobileMenuTrigger.propTypes = {
  isMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func
}

export default MobileMenuTrigger
