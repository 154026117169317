import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/common/components/navbar.module.scss'
import Item from './item'

const Brand = ({ children, ...attrs }) => {
  const { className } = attrs
  attrs.className = classNames(className, {
    [styles.brand]: true
  })

  return (
    <Item {...attrs}>
      {children}
    </Item>
  )
}

Brand.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default Brand
