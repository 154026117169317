import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/common/components/navbar.module.scss'
import Item from './item'

const Spacer = ({ ...attrs }) => {
  const { className } = attrs

  attrs.className = classNames(className, {
    [styles.spacer]: true
  })

  return (
    <Item {...attrs} />
  )
}

Spacer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default Spacer
