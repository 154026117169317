import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Icon from '../icon'
import Typography from '../typography'
import MobileMenuTrigger from './mobile-menu-trigger'

import { PATH_MAP } from './constants'
import { getShortName } from '../user/functions'
import { useAuth } from '../../../hooks/auth'

import ArrowRight from '../icon/arrow-right.svg'
import ChevronRight from '../icon/chevron-right.svg'
import UserIcon from '../icon/user.svg'
import AppStoreIcon from '../../../public/img/bantrab/logo-appstore.svg'
import GooglePlayIcon from '../../../public/img/bantrab/logo-googleplay.svg'

import { useBreakpoint } from '../../../hooks/breakpoint'

const MobileMenu = ({ menu, mobileapplications = {}, active, onClose }) => {
  const auth = useAuth()
  const { asPath } = useRouter()
  const breakpoint = useBreakpoint()

  return <>
    {
      active
        ? <div
          className={
            classNames([
              'bg-green-600',
              'fixed',
              'z-100',
              'top-14',
              'md:top-20',
              'left-0',
              'right-0',
              'bottom-0',
              'md:text-center',
              'overflow-y-auto',
              'pt-4'
            ])
          }
          style={{
            height: `calc(100vh - ${breakpoint.mdAndUp ? '5rem' : '3.5rem'})`
          }}
          onClick={onClose}>
          <div
            className={
              classNames([
                'flex',
                'flex-col',
                'min-h-full',
                (auth && auth.isAuthenticated && auth.user) ? 'justify-between' : 'justify-around'
              ])
            }>
            {
              (auth && auth.isAuthenticated && auth.user)
                ? <div
                  className={
                    classNames([
                      'p-4',
                      'flex',
                      'flex-row',
                      'items-center',
                      'justify-between',
                      'cursor-pointer',
                      'text-white',
                      'bg-green-500',
                      'bg-opacity-30'
                    ])
                  }>
                  <div
                    className={'flex flex-row items-center'}>
                    <Icon
                      width={20}
                      height={20}
                      svg={UserIcon}
                      className={'mr-2'} />
                    <Typography
                      clamp={1}
                      size={'three'}
                      className={'text-turquoise-500'}>
                      ¡Hola, {getShortName(auth.user)}!
                    </Typography>
                  </div>
                  <Link
                    href={'/perfil'}>
                    <a
                      className={'block'}>
                      <Typography
                        clamp={1}
                        type={'link'}
                        size={'three'}
                        className={'text-turquoise-500'}>
                        Mi perfil
                      </Typography>
                    </a>
                  </Link>
                </div>
                : null
            }
            <div
              className={'px-4'}>
              <Link
                href={'/'}>
                <a
                  className={
                    classNames([
                      'flex',
                      'justify-between',
                      'items-center',
                      'py-4',
                      'border-b-2',
                      'border-white',
                      'ease-in-out',
                      'duration-500',
                      'transition-colors',
                      'hover:text-turquoise-500'
                    ], {
                      'text-white': (asPath !== '/'),
                      'text-turquoise-500': (asPath === '/')
                    })
                  }>
                  <Typography
                    tag={'span'}
                    size={'three'}
                    weight={'bold'}>
                    Inicio
                  </Typography>
                  <Icon
                    width={24}
                    height={24}
                    svg={ChevronRight} />
                </a>
              </Link>

              {
                menu && menu.map((item, index) => {
                  const href = item.href ? `${asPath.split('#')[0]}${item.href}` : PATH_MAP[item.type]
                  const isActive = asPath.startsWith(href)

                  return <div key={index}>
                    <Link
                      href={href}>
                      <a
                        className={
                          classNames([
                            'flex',
                            'justify-between',
                            'items-center',
                            'py-4',
                            'border-b-2',
                            'border-white',
                            'ease-in-out',
                            'duration-500',
                            'transition-colors',
                            'hover:text-turquoise-500'
                          ], {
                            'text-white': !isActive,
                            'text-turquoise-500': isActive
                          })
                        }>
                        <Typography
                          tag={'span'}
                          size={'three'}
                          weight={'bold'}>
                          {item.name}
                        </Typography>
                        <Icon
                          width={24}
                          height={24}
                          svg={ChevronRight} />
                      </a>
                    </Link>
                  </div>
                })
              }

              {
                (auth && auth.isAuthenticated && auth.user)
                  ? null
                  : <Link
                    href="/auth/signin">
                    <a
                      className={
                        classNames([
                          'w-full',
                          'mt-10',
                          'p-3',
                          'flex',
                          'flex-row',
                          'justify-center',
                          'items-center',
                          'text-white',
                          'bg-green-500'
                        ])
                      }
                      style={{
                        borderRadius: '0 1.5rem 1.5rem 1.5rem'
                      }}>
                      <Typography
                        size={'three'}
                        weight={'medium'}>
                        Iniciar Sesión
                      </Typography>
                      <Icon
                        width={24}
                        height={24}
                        svg={ArrowRight} />
                    </a>
                  </Link>
              }

              {
                (mobileapplications.googlePlayStore || mobileapplications.appleAppStore)
                  ? <div
                    className={'mt-10'}>
                    <Typography
                      weight={'bold'}
                      size={'four'}
                      className={
                        classNames([
                          'mb-4',
                          'md:mb-2',
                          'text-white',
                          'text-center'
                        ])
                      }>
                      Disponible en
                    </Typography>
                    <div
                      className={'flex flex-row flex-wrap items-center justify-center -m-2'}>
                      {
                        mobileapplications.googlePlayStore
                          ? <a
                            href={mobileapplications.googlePlayStore}
                            target={'_blank'}
                            rel={'noreferrer'}
                            className={'m-2 rounded border border-white py-2 text-white'}
                            style={{
                              flexBasis: 'calc(50% - 1rem)'
                            }}>
                            <GooglePlayIcon
                              className={'block mx-auto'}
                              height={24} />
                          </a>
                          : null
                      }
                      {
                        mobileapplications.appleAppStore
                          ? <a
                            href={mobileapplications.appleAppStore}
                            target={'_blank'}
                            rel={'noreferrer'}
                            className={'m-2 rounded border border-white py-2 text-white'}
                            style={{
                              flexBasis: 'calc(50% - 1rem)'
                            }}>
                            <AppStoreIcon
                              className={'block mx-auto'}
                              height={24} />
                          </a>
                          : null
                      }
                    </div>
                  </div>
                  : null
              }

            </div>

            {
              auth.isAuthenticated
                ? <div
                  className={'p-5 text-center text-white cursor-pointer bg-red-500 mt-10'}
                  onClick={
                    () => auth.signout({
                      successURL: '/'
                    })
                  }>
                  <Typography
                    size={'three'}
                    weight={'bold'}
                    className={'uppercase'}>
                    Cerrar Sesión
                  </Typography>
                </div>
                : null
            }
          </div>
        </div>
        : null
    }
  </>
}

MobileMenu.propTypes = {
  menu: PropTypes.array,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  mobileapplications: PropTypes.object
}

MobileMenu.Trigger = MobileMenuTrigger

export default MobileMenu
