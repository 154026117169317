import { useState } from 'react'
import classNames from 'classnames'

import Link from 'next/link'

import BaseNavbar from '../../common/navbar'
import Icon from '../icon'
import Typography from '../typography'

import { getShortName } from '../user/functions'
import { useAuth } from '../../../hooks/auth'

import ChevronDown from '../icon/chevron-down.svg'
import ChevronUp from '../icon/chevron-up.svg'
import UserIcon from '../icon/user.svg'

const SignInIndicator = () => {
  const auth = useAuth()

  const profilePath = '/perfil'
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleSignOut = () => {
    auth.signout({ successURL: '/' })
  }

  return (auth && auth.isAuthenticated && auth.user)
    ? <>
      <BaseNavbar.Item
        className={'relative'}>
        <div
          className={
            classNames([
              'flex',
              'flex-row',
              'justify-center',
              'items-center',
              'relative',
              'rounded-3xl',
              'cursor-pointer',
              'p-1'
            ])
          }
          onClick={toggleMenu}>
          <Icon
            width={20}
            height={20}
            svg={UserIcon}
            className={'text-white mr-2'} />
          <Typography
            size={'three'}
            weight={'medium'}
            className={'text-turquoise-500 mr-2'}
            clamp={1}>
            ¡Hola, {getShortName(auth.user)}!
          </Typography>
          <Icon
            size={20}
            className={'text-turquoise-500'}
            svg={isMenuOpen ? ChevronUp : ChevronDown} />
        </div>
        {
          isMenuOpen
            ? <div
              className={
                classNames([
                  'bg-white',
                  'absolute',
                  'left-0',
                  'right-0',
                  'top-full',
                  'mt-2',
                  'shadow-md',
                  'rounded-xl',
                  'py-4'
                ])
              }>
              <Link
                href={profilePath}>
                <a
                  className={
                    classNames([
                      'px-4',
                      'py-2',
                      'flex',
                      'flex-row',
                      'items-center',
                      'hover:bg-green-50',
                      'hover:text-green-500'
                    ])
                  }>
                  <Icon
                    size={24}
                    svg={UserIcon}
                    className={'text-green-01 mr-2'} />
                  <Typography
                    size={'three'}>
                    Perfil
                  </Typography>
                </a>
              </Link>
              <div
                className="border-t border-green-300 mx-4" />
              <div
                className={
                  classNames([
                    'px-4',
                    'py-2',
                    'flex',
                    'flex-row',
                    'items-center',
                    'hover:bg-green-50',
                    'hover:text-green-500'
                  ])
                }>
                <Typography
                  size={'three'}
                  onClick={handleSignOut}>
                  Salir
                </Typography>
              </div>
            </div>
            : null
        }
      </BaseNavbar.Item>
    </>
    : null
}

export default SignInIndicator
