import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'

import Typography from '../typography'
import BaseNavbar from '../../common/navbar'
import Brand from './brand'
import SignInIndicator from './signin-indicator'
import SignInTrigger from './signin-trigger'
import MobileMenu from './mobile-menu'

import { useAuth } from '../../../hooks/auth'
import { useBreakpoint } from '../../../hooks/breakpoint'
import { PATH_MAP } from './constants'

import Partners from '../../../public/img/bantrab/partners-color.svg'

const Navbar = ({ menu, mobileapplications = {}, ...attrs }) => {
  const auth = useAuth()
  const { asPath } = useRouter()
  const breakpoint = useBreakpoint()
  const [isMenuOpen, setIsOpenMenu] = useState(false)

  const toggleMenu = () => {
    setIsOpenMenu(!isMenuOpen)
  }

  useEffect(() => {
    breakpoint.lgAndUp && setIsOpenMenu(false)
  }, [breakpoint, asPath])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const html = window.document.querySelector('html')
      isMenuOpen
        ? html.classList.add('overflow-hidden')
        : html.classList.remove('overflow-hidden')
    }
  }, [isMenuOpen])

  attrs.className = classNames(
    attrs.className,
    [
      isMenuOpen ? 'bg-green-600' : 'bg-white',
      'relative',
      'w-full',
      'z-50'
    ]
  )
  attrs.clean = true

  return <BaseNavbar {...attrs} compact={breakpoint.smOnly}>
    <Brand
      useVariant={isMenuOpen} />
    {
      isMenuOpen
        ? null
        : <BaseNavbar.Spacer
          style={{
            flex: breakpoint.lgAndUp ? null : 'none',
            width: breakpoint.lgAndUp ? null : (breakpoint.mdAndUp ? '5rem' : '3rem')
          }} />
    }
    <div
      className={
        classNames([
          'h-full',
          'flex',
          'flex-1',
          'flex-row',
          'pl-20',
          'bg-green-600'
        ])
      }
      style={{
        borderRadius: '0 0 0 5rem',
        marginRight: '-100vw',
        paddingRight: '100vw'
      }}>
      {
        breakpoint.lgAndUp
          ? <>
            {
              menu && menu.map((item, index) => {
                const href = item.href ? `${asPath.split('#')[0]}${item.href}` : PATH_MAP[item.type]
                const isActive = asPath.startsWith(href)

                return <BaseNavbar.Item
                  key={index}
                  className={
                    classNames([
                      'border-b-4',
                      isActive ? 'border-turquoise-500' : 'border-transparent'
                    ])
                  }>
                  <Link
                    href={href}>
                    <a>
                      <Typography
                        size={'three'}
                        weight={'regular'}
                        className={
                          classNames([
                            'ease-in-out',
                            'duration-500',
                            'transition-colors',
                            'hover:text-turquoise-500'
                          ], {
                            'text-turquoise-500': isActive,
                            'text-white': !isActive
                          })
                        }>
                        {item.name}
                      </Typography>
                    </a>
                  </Link>
                </BaseNavbar.Item>
              })
            }
            <BaseNavbar.Spacer
              style={{
                flex: breakpoint.mdAndUp ? 'none' : null,
                width: breakpoint.mdAndUp ? '3rem' : null
              }} />
            {
              auth.isAuthenticated
                ? <SignInIndicator />
                : <SignInTrigger />
            }
          </>
          : <>
            <BaseNavbar.Spacer />
            <MobileMenu.Trigger
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu} />
          </>
      }
    </div>
    <div
      className={
        classNames(
          [
            'absolute',
            'left-0',
            'bg-gray-100',
            'md:pr-12'
          ],
          {
            'right-0': breakpoint.smOnly
          }
        )
      }
      style={{
        top: '100%',
        borderRadius: breakpoint.mdAndUp ? '0 3rem 0 0' : null,
        marginLeft: '-100vw',
        paddingLeft: 'calc(100vw + 1rem)',
        marginRight: breakpoint.mdAndUp ? null : '-100vw',
        paddingRight: breakpoint.mdAndUp ? null : 'calc(100vw + 1rem)'
      }}>
      <Partners
        className={'block mx-auto'}
        height={breakpoint.mdAndUp ? 48 : null}
        style={{
          width: breakpoint.mdAndUp ? null : '100%',
          maxWidth: breakpoint.mdAndUp ? null : '18.5rem',
          height: 'auto'
        }} />
    </div>
    <MobileMenu
      menu={menu}
      active={isMenuOpen}
      onClose={toggleMenu}
      mobileapplications={mobileapplications} />
  </BaseNavbar>
}

Navbar.propTypes = {
  menu: PropTypes.array,
  mobileapplications: PropTypes.object
}

export default Navbar
