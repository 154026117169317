import { useState } from 'react'
import classNames from 'classnames'
import Link from 'next/link'

import Icon from '../icon'
import ArrowRight from '../icon/arrow-right.svg'
import Typography from '../typography'

const SignupCall = ({ ...attrs }) => {
  const [email, setEmail] = useState('')

  return <div
    {...attrs}>
    <div
      className={
        classNames([
          'border',
          'border-gray-300',
          'bg-white',
          'flex',
          'flex-row',
          'items-center',
          'overflow-hidden',
          'w-full'
        ])
      }
      style={{
        borderRadius: '1.5rem'
      }}>
      <input
        defaultValue={email}
        placeholder={'Ingresa tu email'}
        className={
          classNames([
            'py-3',
            'px-3',
            'outline-none'
          ])
        }
        style={{
          borderRadius: '1.5rem 0 0 1.5rem',
          width: 'calc( 100% - 136px )'
        }}
        onChange={event => setEmail(event.target.value)} />
      <Link
        href={`/auth/signup?email=${email}`}
      >
        <a
          className={
            classNames([
              'py-3',
              'px-4',
              'bg-green-500',
              'text-white',
              'flex',
              'flex-row',
              'items-center',
              'justify-center',
              'hover:bg-green-400',
              'active:bg-green-600'
            ])
          }
          style={{
            borderRadius: '0 1.5rem 1.5rem 1.5rem',
            flexBasis: '136px',
            width: '136px'
          }}>
          <Typography>
            Regístrate
          </Typography>
          <Icon
            width={20}
            height={20}
            svg={ArrowRight}
            className={'ml-2'} />
        </a>
      </Link>
    </div>
  </div>
}

export default SignupCall
