import Link from 'next/link'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Icon from '../icon'
import Typography from '../typography'
import BaseNavbar from '../../common/navbar'

import User from '../icon/user.svg'

const SignInTrigger = () => {
  const signInPath = '/auth/signin'

  const router = useRouter()

  return <BaseNavbar.Item>
    <Link
      href={{
        pathname: signInPath,
        query: {
          nexturl: router.asPath
        }
      }}>
      <a>
        <Typography
          size={'three'}
          weight={'regular'}
          className={
            classNames([
              'flex',
              'flex-row',
              'items-center',
              'justify-center',
              'text-turquoise-500'
            ])
          }>
          <Icon
            className={'mr-2'}
            svg={User}
            width={20}
            height={20} />
          Inicia sesión
        </Typography>
      </a>
    </Link>
  </BaseNavbar.Item>
}

export default SignInTrigger
