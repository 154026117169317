import PropTypes from 'prop-types'
import Link from 'next/link'

import BaseNavbar from '../../common/navbar'

import BannuLogo from '../../../public/img/bantrab/logo-bannu-01.svg'
import BannuLogo2 from '../../../public/img/bantrab/logo-bannu-02.svg'
// import BannuLogoMobile from '../../../public/img/bantrab/logo-bannu-03.svg'

import { useBreakpoint } from '../../../hooks/breakpoint'

const Brand = ({ useVariant }) => {
  const breakpoint = useBreakpoint()
  const homePath = '/'

  return <>
    <BaseNavbar.Brand>
      <Link href={homePath}>
        <a>
          {
            useVariant
              ? <BannuLogo
                height={breakpoint.mdAndUp ? 48 : 32} />
              : breakpoint.mdAndUp
                ? <BannuLogo2
                  height={48} />
                : <BannuLogo2
                  height={32} />
          }
        </a>
      </Link>
    </BaseNavbar.Brand>
  </>
}

Brand.propTypes = {
  useVariant: PropTypes.bool
}

export default Brand
