import PropTypes from 'prop-types'
import { useAuth } from '../../../hooks/auth'

import Head from '../../common/head'
import Navbar from '../navbar'
import Footer from '../footer'
import Typography from '../typography'

import Send from '../icon/send.svg'
import Mail from '../icon/mail.svg'
import Close from '../../common/icon/close.svg'

const View = ({ head, navbar, footer, children }) => {
  const {
    passwordRecoveryEmailSent,
    setPasswordRecoveryEmailSent,
    suggestionSent,
    setSuggestionSent
  } = useAuth()

  return <>
    <Head {...head} />
    {
      passwordRecoveryEmailSent
        ? <div
          className="bg-turquoise-200 text-black text-center py-4 relative">
          <div
            className="container block mx-auto pr-12 relative">
            <Typography
              size="two">
              <Mail
                className="inline-block mr-4 text-turquoise-600"
                height="24"
                width="24" />
              Hemos enviado un enlace para restablecer tu contraseña.
            </Typography>
          </div>
          <Close
            width="16"
            height="16"
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setPasswordRecoveryEmailSent(false)} />
        </div>
        : null
    }
    {
      suggestionSent
        ? <div
          className="bg-turquoise-200 text-black text-center py-4 relative">
          <div
            className="container block mx-auto pr-12 relative">
            <Typography
              size="two">
              <Send
                className="inline-block mr-4 text-turquoise-600"
                height="24"
                width="24" />
              Sugerencia enviada.
            </Typography>
          </div>
          <Close
            width="16"
            height="16"
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setSuggestionSent(false)} />
        </div>
        : null
    }
    <Navbar {...navbar} />
    <main className={'z-0 min-h-screen'}>
      {children}
    </main>
    <Footer {...footer} />
  </>
}

View.propTypes = {
  head: PropTypes.object,
  navbar: PropTypes.object,
  children: PropTypes.any,
  footer: PropTypes.object
}

export default View
