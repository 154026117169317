import { useState } from 'react'
import classNames from 'classnames'

import Icon from '../icon'
import Typography from '../typography'
import SignupCall from '../auth/signup-call'
import { useBreakpoint } from '../../../hooks/breakpoint'

import Close from '../icon/x.svg'

const StickyForm = () => {
  const breakpoint = useBreakpoint()

  const [isFixed, setIsFixed] = useState(true)

  return <div
    className={
      classNames([
        'relative',
        'z-50'
      ])
    }
    style={{ height: breakpoint.mdAndUp ? '6.125rem' : '8.125rem' }}>
    <div
      className={
        classNames([
          isFixed ? 'fixed' : 'relative',
          isFixed ? 'bottom-0' : '',
          isFixed ? 'left-0' : '',
          isFixed ? 'right-0' : '',
          'bg-green-600',
          'w-full'
        ])
      }>
      <div
        className={
          classNames([
            'relative',
            'w-full'
          ])
        }>
        <div
          className={
            classNames([
              'relative',
              'container',
              'px-4',
              'py-6',
              'flex',
              'flex-col',
              'md:flex-row',
              'justify-center',
              'items-center'
            ])
          }>
          <Typography
            size={'three'}
            weight={'medium'}
            className={'mb-2 md:mb-0 md:mr-4 text-white'}>
            Aprende de los mejores profesionales
          </Typography>
          <SignupCall
            className={
              'w-full'
            }
            style={{
              maxWidth: '24rem'
            }} />
        </div>
        {
          isFixed
            ? <Icon
              width={24}
              height={24}
              svg={Close}
              className={
                classNames([
                  'absolute',
                  'text-white',
                  'cursor-pointer',
                  breakpoint.mdAndUp ? 'right-4' : 'right-0',
                  breakpoint.mdAndUp ? 'top-1/2' : 'top-0',
                  breakpoint.mdAndUp ? 'transform' : '',
                  breakpoint.mdAndUp ? '-translate-y-1/2' : ''
                ])
              }
              onClick={() => setIsFixed(false)} />
            : null
        }
      </div>
    </div>
  </div>
}

export default StickyForm
