export const getFullName = (user) => {
  let { email, firstName, lastName } = user

  firstName = (firstName || '').trim()
  lastName = (lastName || '').trim()

  return `${firstName} ${lastName}`.trim() || email.split('@')[0]
}

export const getShortName = (user) => {
  let { email, firstName } = user

  firstName = (firstName || '').trim().split(' ')[0]

  return firstName || email.split('@')[0]
}
