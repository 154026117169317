import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/common/components/navbar.module.scss'

import Brand from './brand'
import Divider from './divider'
import Item from './item'
import Spacer from './spacer'

const Navbar = ({ children, compact, clean, ...attrs }) => {
  const { className } = attrs

  attrs.className = classNames(className, {
    [styles.navbar]: true,
    [styles.compact]: compact,
    [styles.clean]: clean
  })

  return (
    <nav {...attrs}>
      <div className={`relative container px-4 ${styles.container}`}>
        {children}
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  compact: PropTypes.bool,
  clean: PropTypes.bool
}

Navbar.Brand = Brand
Navbar.Divider = Divider
Navbar.Item = Item
Navbar.Spacer = Spacer

export default Navbar
